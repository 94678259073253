import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as JSTool from '../store/JSTool';
import { settingActions } from '../store/SettingSlice';
import $ from 'jquery';
import * as APJHttp from '../store/APJHttp';


export default function SubmitEmailAlert_CR_Poster(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function closeTap() {
        dispatch(settingActions.showCREmailAlert(false));
    }

    return (
        <Dialog
            fullScreen={true}
            PaperProps={{
                style: {
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000099',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                },
            }}
            open={settingData.showCREmailAlert}>

            <Stack sx={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                height: settingData.isMobile ? '550rem' : '400rem',
                width: settingData.isMobile ? 0.95 :'700rem',
                bgcolor: 'black',

            }}>
                {!settingData.submitedEmailCR && <EmailContainer />}
                {settingData.submitedEmailCR && <EmailSuccess />}

                <Box
                    component={'img'}
                    onClick={closeTap}
                    src={JSTool.getImgPath('close1.png')}
                    sx={{
                        alignSelf: 'flex-end',
                        cursor: 'pointer',
                        width: '16rem',
                        position: 'absolute',
                        zIndex: 10,
                        top: '20rem',
                        right: '20rem',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'rotate(180deg)',
                        }
                    }} />
            </Stack>

        </Dialog>
    );
}

function EmailContainer() {

    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);
    const [checked2, setChecke2] = React.useState(false);
    const settingData = useSelector(state => state.settingData);


    function submitTap() {
        const e0 = $('#input_one')[0].value;
        const e1 = $('#input_two')[0].value;

        if (!JSTool.isEmail(e0) || !JSTool.isEmail(e1)) {
            dispatch(settingActions.showToast('Please enter a valid email address'));
            return
        }

        if (e0 != e1) {
            dispatch(settingActions.showToast('The two Emails are different'));
            return;
        }

        if (!checked) {
            dispatch(settingActions.showToast('Please agree the Privacy Policy'));
            return;
        }

        if (!checked2) {
            dispatch(settingActions.showToast('Please agree the rules of the sweepstakes'));
            return;
        }

        //提交网络请求
        JSTool.trackEvent('email_submit');
        dispatch(settingActions.showLoading(true));
        APJHttp.addEmailCR(e0, (data) => {
            dispatch(settingActions.showLoading(false));
            dispatch(settingActions.setSubmitedEmailCR(e0));
        }, (code, msg) => {
            dispatch(settingActions.showLoading(false));
            dispatch(settingActions.showToast(`code ${code}: ${msg}`));
        })
    }

    function checkTap() {
        setChecked((oldState) => {
            return !oldState;
        });
    }

    function checkTap2() {
        setChecke2((oldState) => {
            return !oldState;
        });
    }

    return (
        <Stack
            id="cr_email_div"
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                bottom: settingData.isMobile ? '80rem' : '90rem',
                px: settingData.isMobile ? 2 : 8,
                pt: 2,
            }}>

            {/* 输入1 */}
            <Stack sx={{
                bgcolor: 'white',
                height: '35rem',
                width: '1',
                flexDirection: 'row',
            }}>
                <Typography sx={{
                    width: '230rem',
                    height: 1,
                    bgcolor: 'black',
                    fontSize: '16rem',
                    color: 'white',
                    textAlign: 'left',
                    lineHeight: '40rem',
                    pl: 1.5,
                    fontFamily: 'Lato, sans-serif',
                }}>
                    Please enter your email:
                </Typography>

                <Stack
                    id='input_one'
                    component={'input'}
                    type={'email'}
                    placeholder={''}
                    sx={{
                        px: 2,
                        fontSize: '16rem',
                        border: 0,
                        outline: 'none',
                        color: 'black',
                        flex: 1,
                        fontFamily: 'Lato, sans-serif',
                        '&::placeholder': {
                            color: "gray",
                            fontFamily: 'Lato, sans-serif',
                        },
                    }} />

            </Stack>

            {/* 输入2 */}
            <Stack sx={{
                mt: 2,
                bgcolor: 'white',
                height: '35rem',
                width: '1',
                flexDirection: 'row',
            }}>

                <Typography sx={{
                    width: '230rem',
                    height: 1,
                    bgcolor: 'black',
                    fontSize: '16rem',
                    color: 'white',
                    textAlign: 'left',
                    lineHeight: '40rem',
                    pl: 1.5,
                    fontFamily: 'Lato, sans-serif',
                }}>
                    Please re-enter your email:
                </Typography>

                <Stack
                    id='input_two'
                    component={'input'}
                    type={'email'}
                    placeholder={''}
                    sx={{
                        px: 2,
                        fontSize: '16rem',
                        border: 0,
                        outline: 'none',
                        color: 'black',
                        flex: 1,
                        fontFamily: 'Lato, sans-serif',
                        '&::placeholder': {
                            color: "gray",
                            fontFamily: 'Lato, sans-serif',
                        },
                    }} />

            </Stack>

            {/* 协议1 */}
            <Stack sx={{
                mt: 4,
                width: '1',
                position: 'relative',
            }}>
                <Box
                    component={'div'}
                    sx={{
                        fontSize: '15rem',
                        color: 'white',
                        textAlign: 'left',
                        lineHeight: '20rem',
                        pl: '40rem',
                        py: 1,
                        fontFamily: 'Lato, sans-serif',
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        By submitting your email address, you acknowledge that you have read Crunchyroll\'s <a target="_blank" href="https://www.sonypictures.com/corp/privacy.html">Privacy Policy</a>. You can opt out at any time.
                        `
                    }} />

                <Box component={'img'}
                    onClick={checkTap}
                    src={checked ? JSTool.getImgPath('white_selected3.png') : JSTool.getImgPath('white_not_selected.png')}
                    sx={{
                        width: '15rem',
                        position: 'absolute',
                        left: '10rem',
                        top: '10rem',
                        cursor: 'pointer'
                    }} />

            </Stack>

            {/* 协议2 */}
            <Stack sx={{
                mt: 2,
                width: '1',
                position: 'relative',
            }}>
                <Box
                    component={'div'}
                    sx={{
                        fontSize: '15rem',
                        color: 'white',
                        textAlign: 'left',
                        lineHeight: '20rem',
                        pl: '40rem',
                        py: 1,
                        fontFamily: 'Lato, sans-serif',
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        By submitting your email address, you confirm being at least 18 years old and have read <a target='_blank' href='https://www.crunchyroll.com/terms/lon-sweepstakes-24'>the rules of the sweepstakes<a/>.
                         `
                    }} />

                <Box component={'img'}
                    onClick={checkTap2}
                    src={checked2 ? JSTool.getImgPath('white_selected3.png') : JSTool.getImgPath('white_not_selected.png')}
                    sx={{
                        width: '15rem',
                        position: 'absolute',
                        left: '10rem',
                        top: '10rem',
                        cursor: 'pointer'
                    }} />

            </Stack>

            {/* 提交按钮 */}
            <Typography
                onClick={submitTap}
                sx={{
                    mt: 3,
                    width: '130rem',
                    height: '45rem',
                    lineHeight: '45rem',
                    bgcolor: '#FE6511',
                    fontSize: '17rem',
                    color: 'black',
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                }}>
                SUBMIT
            </Typography>

        </Stack>
    );
}


function EmailSuccess(props) {

    return (
        <Typography sx={{
            fontSize: '22rem',
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            bottom: '190rem',
            px: 2,
            py: 2,
            fontFamily: 'Lato, sans-serif',
            textAlign: 'center',
        }}>
            Thank you for participating!<br/> 
            Stay tuned!
        </Typography>
    );
}

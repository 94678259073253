import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getImgPath } from '../store/JSTool';
import $ from 'jquery';
import TitleView from '../component/TitleView';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, EffectFade } from 'swiper/modules';


export default function Page4(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [swp, setSwp] = React.useState(null);
    const [currentIindex, setCurrentIindex] = React.useState(0);
    let imgAry = [
        "p_1_2.jpg", "p_2_2.jpg", "p_3_2.jpg", "p_4_2.jpg", "p_5_2.jpg",
        "p_1_2.jpg", "p_2_2.jpg", "p_3_2.jpg", "p_4_2.jpg", "p_5_2.jpg",
        "p_1_2.jpg", "p_2_2.jpg", "p_3_2.jpg", "p_4_2.jpg", "p_5_2.jpg",
    ];

    let imgAry_CR = [
        "p_1_cr_2.png", "p_2_cr_2.png", "p_3_cr_2.png", "p_4_cr_2.png", "p_5_cr_2.png",
        "p_1_cr_2.png", "p_2_cr_2.png", "p_3_cr_2.png", "p_4_cr_2.png", "p_5_cr_2.png",
        "p_1_cr_2.png", "p_2_cr_2.png", "p_3_cr_2.png", "p_4_cr_2.png", "p_5_cr_2.png",
    ];

    React.useEffect(() => {

    }, []);

    function getAry(){
        if(JSTool.isCR()){
            return imgAry_CR;
        }
        return imgAry;
    }

    function updateIndex(index) {//swp index
        let x = JSTool.isMobile ? index : index + 1;
        if (x >= getAry().length) {
            x = 0;
        }
        setCurrentIindex(x);
    }

    function indexChanged() {
        if (swp) {
            updateIndex(swp.realIndex);
        }
    }

    function leftTap() {
        if (swp) {
            swp.slidePrev();
        }
    }

    function rightTap() {
        if (swp) {
            swp.slideNext();
        }
    }

    function itemTap(index) {
        if (swp) {
            if(index == currentIindex + 1 || (currentIindex - (getAry().length-1) == index)){
                rightTap();
            }else  if(index == currentIindex - 1 || (currentIindex + (getAry().length-1)) == index){
                leftTap();
            }
        }
    }

    return (
        <Stack
            id={'page4'}
            sx={{
                width: 1,
                overflow: 'hidden',
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: `url(${getImgPath('bg4_3.png')})`,
                alignItems: 'center',
                position: 'relative',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                pb: 2,
            }}>

            <TitleView title={'INTRODUCTION'} anchorId={props.anchorId} mt={5} />

            {/* 图片 */}
            <Stack sx={{
                alignItems: 'center',
                justifyContent: 'center',
                mt: 6,
                position: 'relative',
                width: 1,
            }}>
                <Box
                    component={'img'}
                    src={JSTool.getImgPath('intro_bg2.png')}
                    sx={{
                        position: 'absolute',
                        width: settingData.isMobile ? 0.8 : '900rem',
                        height: settingData.isMobile ? '400rem' : '550rem',
                    }} />

                <Box
                    component={'img'}
                    src={getImgPath(`poster/${getAry()[currentIindex]}`)}
                    sx={{
                        width: settingData.isMobile ? 0.765 : '855rem',
                        height: settingData.isMobile ? '370rem' : '505rem',
                    }} />

            </Stack>

            {/* 切换 */}
            <Stack sx={{
                height: '270rem',
                position: 'relative',
                alignItems: 'center',
                flexDirection: 'row',
                mt: 2,
            }}>
                {/* 黑色阴影背景 */}
                <Stack sx={{
                    flexDirection: 'row',
                    height: '140rem',
                }}>
                    <Box sx={{
                        width: '450rem',
                        background: 'linear-gradient(90deg, #00000000 , #000000cc)',
                    }} />
                    <Box sx={{
                        width: '300rem',
                        background: '#000000cc',
                    }} />
                    <Box sx={{
                        width: '450rem',
                        background: 'linear-gradient(-90deg, #00000000 , #000000cc)',
                    }} />
                </Stack>

                {/* 控件 */}
                <Stack sx={{
                    position: 'absolute',
                    width: 1,
                    height: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box
                        onClick={leftTap}
                        component={'img'}
                        src={JSTool.getImgPath('swip_left.png')}
                        sx={{
                            height: '35rem',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.5)',
                            }
                        }} />

                    <Swiper
                        style={{
                            width: settingData.isMobile ? '50%' : '85%',
                            margin: settingData.isMobile ? 0 : '10rem',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        }}

                        modules={[Autoplay]}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}

                        onSwiper={(sp) => {
                            setSwp(sp);
                            updateIndex(0);
                        }}

                        onSlideChangeTransitionEnd={(sp) => {
                            indexChanged();
                        }}

                        spaceBetween={settingData.isMobile ? 10 : 20}
                        slideToClickedSlide={false}
                        slidesPerView={settingData.isMobile ? 2 : 3}
                        loop={true}>

                        {
                            getAry().map((data, i) =>
                                <SwiperSlide key={i}>
                                    <ImageItem data={data} index={i} currentIindex={currentIindex} itemTap={itemTap} />
                                </SwiperSlide>
                            )
                        }
                    </Swiper>

                    <Box
                        onClick={rightTap}
                        component={'img'}
                        src={JSTool.getImgPath('swip_right.png')}
                        sx={{
                            height: '35rem',
                            objectFit: 'cover',
                            cursor: 'pointer',
                            transitionDuration: '0.3s',
                            '&:hover': {
                                transform: 'scale(1.5)',
                            }
                        }} />
                </Stack>
            </Stack>
        </Stack>
    );
}


function ImageItem(props) {
    return (
        <Stack
            onClick={() => {
                props.itemTap(props.index);
            }}
            sx={{
                cursor: 'pointer',
                width: 1,
                height: '240rem',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Box
                component={'img'}
                src={JSTool.getImgPath(`poster/${props.data}`)}
                sx={{
                    position: 'absolute',
                    width: 1,
                    height: "200rem",
                }} />
            <Box
                component={'img'}
                src={JSTool.getImgPath('intro_bg2.png')}
                sx={{
                    boxShadow: (props.index == props.currentIindex) && '0px 0px 2px 2px #8756B8',
                    position: 'absolute',
                    width: 1,
                    height: '200rem',
                }} />

        </Stack>
    );
}

import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import * as APJTool from '../../store/JSTool';
import country from '../../store/country.json';

export default function OneLink_sns_chan() {

    React.useEffect(()=>{
        APJTool.trackEvent('one_link_sns_chan');
        axios.post(APJTool.isCR() ? 'https://am-web-api.aplus-games.com/api/public/geoip' : 'https://eu-web-api.aplus-games.com/api/public/geoip' , {
            headers: {},
        }).then(({data}) => {
            if (data.ip) { //成功
                const ip = data.ip;
                const countryCode = data.country_code;
                
                if(country['apj'].indexOf(countryCode) != -1){ //APJ国家
                    window.location.href = "https://apjoverlord.onelink.me/97jr/y9rnb534";

                }else if(country['cr'].indexOf(countryCode) != -1){//CR国家
                    window.location.href = "https://lon.sng.link/Dv4dv/nmux?_smtype=3";
   
                }
            } 
        }).catch(function (error) {
            console.log(error);
        });
    });

    return (
        <Stack>
         
        </Stack>
    );
}


import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import * as APJTool from '../../store/JSTool';
import country from '../../store/country.json';

export default function OneLink_FAQ() {

    React.useEffect(()=>{
        APJTool.trackEvent('one_link_taped');
        axios.post(APJTool.isCR() ? 'https://am-web-api.aplus-games.com/api/public/geoip' : 'https://eu-web-api.aplus-games.com/api/public/geoip' , {
            headers: {},
        }).then(({data}) => {
            if (data.ip) { //成功
                const ip = data.ip;
                const countryCode = data.country_code;
                
                if(country['apj'].indexOf(countryCode) != -1){ //APJ国家
                    window.location.href = "https://aplusjapan.aihelp.net/webchatv4/#/appKey/APLUSJAPAN_app_5eab76c2122a4f99916556acf3edcf2c/domain/aplusjapan.aihelp.net/appId/aplusjapan_platform_e5d87d28799f4331205f114876ee9f19?entranceId=E001&language=en";

                }else if(country['cr'].indexOf(countryCode) != -1){//CR国家
                    window.location.href = "https://games.help.crunchyroll.com/hc/en-us";
   
                }
            } 
        }).catch(function (error) {
            console.log(error);
        });
    });

    return (
        <Stack>
         
        </Stack>
    );
}


import { Stack, Box, } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function LeftDownload(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function appleTap() {
        JSTool.appleTap();
    }

    function googleTap(){
        JSTool.googleTap();
    }

    return (
        <Stack
            id={'home_page_left_view'}
            sx={{
                display: 'none',
                position: 'fixed',
                zIndex: 2,
                left: settingData.isMobile ? 10 : 30,
                top: settingData.isMobile ? 100 : 130,
            }}>
            <Stack sx={{
                alignItems: 'center',
                flexDirection: 'column',
            }}>
                <Box
                    component={'img'}
                    src={JSTool.isCR() ? JSTool.getImgPath('logo_512_cr.png') : JSTool.getImgPath('logo_512.png')}
                    sx={{
                        height: '100rem',
                        borderRadius: '20px',
                    }} />

                <Box
                    onClick={appleTap}
                    component={'img'}
                    src={JSTool.getImgPath('down_apple.png')}
                    sx={{
                        mt: 1,
                        borderRadius: '6px',
                        height: '50rem',
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }} />

                <Box
                    onClick={googleTap}
                    component={'img'}
                    src={JSTool.getImgPath('down_google.png')}
                    sx={{
                        borderRadius: '6px',
                        mt: 1,
                        height: '50rem',
                        transitionDuration: '0.3s',
                        cursor: 'pointer',
                        '&:hover': {
                            transform: 'scale(1.05)',
                        }
                    }} />
            </Stack>
        </Stack>
    );
}
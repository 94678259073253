import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as rdd from 'react-device-detect';

export default function MobileMenu(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function itemTap(i) {
        JSTool.hideMenu();
        JSTool.scrollToView(`scroll_to${i}`);
    }

    return (
        <Stack
            id='menu_view'
            sx={{
                width: 1,
                bgcolor: '#000000cc',
                position: 'absolute',
                zIndex: 999,
                top: rdd.isIPad13 ? '89px' : '49px',
                display: 'none',
            }}>
            {props.isDownload &&
                <Stack>
                    <Item title="HOME" index={0} itemTap={itemTap} />
                    <Item title="PRE-REGISTER" index={1} itemTap={itemTap} />
                    <Item title="CHARACTERS" index={3} itemTap={itemTap} />
                </Stack>
            }

            {!props.isDownload &&
                <Stack>
                    <Item title="HOME" index={0} itemTap={itemTap} />
                    {/* <Item title="PRE-REGISTER" index={1} itemTap={itemTap} /> */}
                    <Item title="NEWS" index={2} itemTap={itemTap} />
                    <Item title="CHARACTERS" index={3} itemTap={itemTap} />
                    <Item title="INTRODUCTION" index={4} itemTap={itemTap} />
                </Stack>
            }

        </Stack>
    );
}

function Item(props) {
    return (
        <Stack onClick={() => props.itemTap(props.index)}>
            <Typography sx={{
                color: 'white',
                fontSize: rdd.isIPad13 ? 16 : 13,
                height: rdd.isIPad13 ? '80px' : '60px',
                lineHeight: rdd.isIPad13 ? '80px' : '55px',
                fontWeight: 'bold',
                pr: 3,
                pl: 3,
                textAlign: 'center',
                fontFamily: window.MyFont,
            }}>
                {props.title}
            </Typography>
            <Box sx={{
                width: 1,
                height: '1px',
                bgcolor: '#C9CDD140',
            }} />
        </Stack>
    );
}
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import settingSlice from './SettingSlice';

const store = configureStore({
    reducer: {
        settingData: settingSlice,
    },
    middleware: [thunk]
});

export default store;
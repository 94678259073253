import axios from 'axios';
import * as APJTool from './JSTool';
import country from './country.json';

const HOST_API_APJ = "https://eu-web-api.aplus-games.com";  
const HOST_API_CR = "https://am-web-api.aplus-games.com";

function getHost(path) {
    if (path.startsWith('http://') || path.startsWith('https://')) {
        return path;
    }

    if (APJTool.isCR()) {
        return HOST_API_CR + path;
    }
    return HOST_API_APJ + path;
}

//添加邮件
export function addEmail(email, onSuccess, onFail) {
    post('/api/client/email/add', { email: email }, onSuccess, onFail);
}

export function addEmailCR(email, onSuccess, onFail) {
    post('https://am-web-api.aplus-games.com/api/client/event/add', { email: email }, onSuccess, onFail);
}

//最新新闻列表
export function getNewsLatest(num, onSuccess, onFail) {
    post('/api/client/web/news/latest', { num: num }, (data) => { onSuccess(data.data) }, onFail);
}

//新闻列表分页
export function getNewsPage(pageIndex, pageNum, onSuccess, onFail) {
    post('/api/backend/web/news/list', { pageIndex: pageIndex, pageNum: pageNum }, (data) => { onSuccess(data.data) }, onFail);
}

//新闻详情
let NewsMap = new Map();
export function getNewsDetail(newsId, onSuccess, onFail) {
    const x = NewsMap.get(newsId);  //缓存
    if (!APJTool.isEmpty(x)) {
        onSuccess(x);
        return;
    }

    post('/api/client/web/news/detail', { id: parseInt(newsId) }, (data) => {
        NewsMap.set(newsId, data.content);
        onSuccess(data.content);
    }, onFail);
}

export function getNewsDetailLocal(newsId) {
    const x = NewsMap.get(newsId);  //缓存
    if (!APJTool.isEmpty(x)) {
        return x;
    }
    return null;
}

const allowedIPList = ["118.242.32.210","182.255.32.51"];
export function getIPInfo(onResult) {
    axios.post(APJTool.isCR() ? 'https://am-web-api.aplus-games.com/api/public/geoip' : 'https://eu-web-api.aplus-games.com/api/public/geoip' , {
        headers: {},
    }).then(({data}) => {
        if (data.ip) { //成功
            const ip = data.ip;
            const countryCode = data.country_code;
            
            if (isPass(allowedIPList, ip)) { //白名单
                onResult(true);
                return;
            }

            if (APJTool.isCR()) {//CR web
                if(country['apj'].indexOf(countryCode) != -1){ //APJ国家
                    window.location.href = "https://lon.aplus-games.com";
                }else if(country['cr'].indexOf(countryCode) != -1){//CR国家
                    onResult(true);
                }else{
                    onResult(false)
                }

            } else {//APJ web
                if(country['cr'].indexOf(countryCode) != -1){ //CR国家
                    window.location.href = "https://www.lordofnazarickgame.com";
                }else if(country['apj'].indexOf(countryCode) != -1){//APJ国家
                    onResult(true);
                }else{
                    onResult(false)
                }
            }

        } else {
            onResult(false);
        }
    }).catch(function (error) {
        console.log(error);
        onResult(false);
    });
}

function isPass(ary, code) {
    var ret = false;
    for (var i = 0; i < ary.length; i++) {
        if (ary[i].toUpperCase() == code.toUpperCase()) {
            ret = true;
            break;
        }
    }
    return ret;
}

//post 请求
export function post(url, param, onSuccess, onFail) {
    axios.post(getHost(url), param, {
        headers: {

        },
    }).then(({ data }) => {
        if (100000 == data.code) { //成功
            onSuccess(data.data);
        } else {
            onFail(data.code, data.msg);
        }
    }).catch(function (error) {
        console.log(error);
        onFail(-1000, 'Request fail, try again later.');
    });
}
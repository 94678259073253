import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from './store/Store';
import { Provider } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from './store/SettingSlice';
import * as rdd from 'react-device-detect';
import { Box, Stack, Typography } from '@mui/material';
import LoadingAndHint from './component/LoadingAndHint';
import { hideMenu } from './store/JSTool';
import $ from 'jquery';
import * as APJHttp from './store/APJHttp';
import * as JSTool from './store/JSTool';

import HomePage from './page/HomePage';
import HomePageMobile from './page/HomePageMobile';
import SubmitEmailAlert from './component/SubmitEmailAlert';
import NewsDetailAlert from './page/NewsDetailAlert';
import TermsPage from './page/TermsPage';
import PrivacyPage from './page/PrivacyPage';
import DownloadPage from './page/DownloadPage';
import DownloadPageMobile from './page/DownloadPageMobile';
import OneLink from './page/onelink/OneLink';
import OneLinkOverlor from './page/onelink/OneLinkOverlor';
import LoadingPage from './page/LoadingPage';
import PostView_CR from './page/PostView_CR';
import OneLink_FAQ from './page/onelink/OneLink_FAQ';

import OneLink_fandom from './page/onelink/OneLink_fandom';
import OneLink_ign from './page/onelink/OneLink_ign';
import OneLink_eurogamer from './page/onelink/OneLink_eurogamer';
import OneLink_pocketgamer from './page/onelink/OneLink_pocketgamer';
import OneLink_gamesradar from './page/onelink/OneLink_gamesradar';
import OneLink_pockettactics from './page/onelink/OneLink_pockettactics';
import OneLink_frdemedia from './page/onelink/OneLink_frdemedia';
import OneLink_myanimelist from './page/onelink/OneLink_myanimelist';
import OneLink_ann from './page/onelink/OneLink_ann';

import OneLink_tectone from './page/onelink/OneLink_tectone';
import OneLink_braxophone from './page/onelink/OneLink_braxophone';
import OneLink_doro44 from './page/onelink/OneLink_doro44';
import OneLink_iwintolose from './page/onelink/OneLink_iwintolose';
import OneLink_abdou from './page/onelink/OneLink_abdou';
import OneLink_lechefotaku from './page/onelink/OneLink_lechefotaku';
import OneLink_mathyki from './page/onelink/OneLink_mathyki';
import OneLink_suchakylie from './page/onelink/OneLink_suchakylie';

import OneLink_webshop from './page/onelink/OneLink_webshop';
import OneLink_sns_reddit from './page/onelink/OneLink_sns_reddit';
import OneLink_sns_youtube from './page/onelink/OneLink_sns_youtube';
import OneLink_sns_x from './page/onelink/OneLink_sns_x';
import OneLink_sns_chan from './page/onelink/OneLink_sns_chan';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/download' element={<AppWraper pc={<DownloadPage />} mb={<DownloadPageMobile />} />} />
                <Route path='/terms' element={<AppWraper pc={<TermsPage />} mb={<TermsPage />} />} />
                <Route path='/privacy' element={<AppWraper pc={<PrivacyPage />} mb={<PrivacyPage />} />} />
                <Route path='/onelink' element={<OneLink />} />
                <Route path='/overlord' element={<OneLinkOverlor />} />
                <Route path='/OVERLORDanimecelebration2024' element={<AppWraper pc={<PostView_CR />} mb={<PostView_CR />} />} />
                <Route path='/overlord_faq' element={<OneLink_FAQ />} />

                <Route path='/olk_fandom' element={<OneLink_fandom />} />
                <Route path='/olk_ign' element={<OneLink_ign />} />
                <Route path='/olk_eurogamer' element={<OneLink_eurogamer />} />
                <Route path='/olk_pocketgamer' element={<OneLink_pocketgamer />} />
                <Route path='/olk_gamesradar' element={<OneLink_gamesradar />} />
                <Route path='/olk_pockettactics' element={<OneLink_pockettactics />} />
                <Route path='/olk_frdemedia' element={<OneLink_frdemedia />} />
                <Route path='/olk_myanimelist' element={<OneLink_myanimelist />} />
                <Route path='/olk_ann' element={<OneLink_ann />} />

                <Route path='/olk_tectone' element={<OneLink_tectone />} />
                <Route path='/olk_braxophone' element={<OneLink_braxophone />} />
                <Route path='/olk_doro44' element={<OneLink_doro44 />} />
                <Route path='/olk_iwintolose' element={<OneLink_iwintolose />} />
                <Route path='/olk_abdou' element={<OneLink_abdou />} />
                <Route path='/olk_lechefotaku' element={<OneLink_lechefotaku />} />
                <Route path='/olk_mathyki' element={<OneLink_mathyki />} />
                <Route path='/olk_suchakylie' element={<OneLink_suchakylie />} />

                <Route path='/webshop' element={<OneLink_webshop />} />
                <Route path='/dlfgjl' element={<OneLink_sns_reddit />} />
                <Route path='/tjrjvp' element={<OneLink_sns_youtube />} />
                <Route path='/wencpt' element={<OneLink_sns_x />} />
                <Route path='/wodfmt' element={<OneLink_sns_chan />} />
                <Route path='/auwihw' element={<OneLink_webshop />} />

            </Routes>
            <LoadingAndHint />
            <SubmitEmailAlert />
            <NewsDetailAlert />
        </BrowserRouter>
    </Provider>
);

function App() {
    const [flag, setFlag] = React.useState(0);
    React.useEffect(() => {
        // setFlag(1);
        APJHttp.getIPInfo((pass) => {
            if (pass) {
                setFlag(1);
            } else {
                setFlag(-1);
            }
        })
    }, []);

    return (
        <Stack>
            {flag == 1 &&
                <AppWraper pc={<HomePage />} mb={<HomePageMobile />} />
            }
            {flag == -1 && <ForbiddenView />}
        </Stack>
    )
}

function ForbiddenView() {
    function isMobile() {
        return rdd.isMobile || rdd.isIPad13 || rdd.isIPhone13 || window.innerWidth < 1025;
    }

    return (
        <Box
            component={'img'}
            src={isMobile() ? JSTool.getImgPath('forbidden_m2.png') : JSTool.getImgPath('forbidden2.png')}
            sx={{
                width: 1,
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'black',
                justifyContent: 'center',
                objectFit: 'cover',
            }} />
    );
}


function AppWraper(props) {

    const settingData = useSelector(state => state.settingData);
    const dispatch = useDispatch();
    let resizeTimer;

    React.useEffect(() => {
        $(window).on("resize", changeSize);
        dispatch(settingActions.setIsMobile(isMobile()));
    }, []);

    function changeSize(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            dispatch(settingActions.refreshInnerHeight());
        }, 250);
    }

    function isMobile() {
        return rdd.isMobile || rdd.isIPad13 || rdd.isIPhone13 || window.innerWidth < 1025;
    }

    return (
        <Stack sx={{
            fontSize: '16rem'//默认值
        }}>
            {isMobile() && props.mb}
            {!isMobile() && props.pc}

            {isMobile() &&
                <Stack
                    id='_mask_view'
                    onClick={() => {
                        hideMenu();
                    }}
                    sx={{
                        position: 'absolute',
                        zIndex: 2,
                        bgcolor: '#00000099',
                        width: 1,
                        height: settingData.innerHeight,
                        display: 'none',
                    }} />
            }
        </Stack>
    )
}
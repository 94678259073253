import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { getImgPath } from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';
import * as JSTool from '../store/JSTool';
import Footer from './Footer';
import SubmitEmailAlert_CR_Poster from '../component/SubmitEmailAlert_CR_Poster';


export default function PostView_CR() {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {

    }, []);

    return (
        <Stack>
            {!JSTool.isAPJ() && <CRView />}
        </Stack>
    );
}

function CRView(props) {
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const hintTxt = "Celebrating the <i>OVERLORD</i> anime with a limited time<br/> promotion available to all Crunchyroll members!";
    const txt_0 = "Log into <i>Lord of Nazarick</i> with your <br/>Crunchyroll account to enjoy<br/> exclusive rewards!";
    const txt_0_2 = "Crunchyroll Subscribers receive <br/>even bigger rewards plus access <br/>to exclusive shop discounts and<br/> battle passes!";

    const txt_1 = "Now Playing Only in Theaters";
    const txt_1_2 = "*United States only. Terms and conditions apply.";
    const txt_2_2 = "*North America only. While supplies last.";
    const txt_3 = "Catch up on the <i>OVERLORD</i> <br/>anime series streaming now<br/> on Crunchyroll!";
    const txt_2 = "Get a free collectible<br/><i>OVERLORD</i> card with any<br/> <i>OVERLORD</i> book purchase!";


    function showCREmailAlert() {
        dispatch(settingActions.showCREmailAlert(true));
    }

    return (
        <Stack sx={{
            bgcolor: 'black',
            width: 1,
            alignItems: 'center',
        }}>

            {/* CR */}
            <Stack sx={{
                borderTop: '10px solid #FF5E00',
                width: 1,
                alignItems: 'center',
            }}>
                <Box component={'img'}
                    src={JSTool.getImgPath('cr_logo_new3.png')}
                    sx={{
                        width: '250rem',
                        my: '25rem',
                    }} />
            </Stack>

            <Stack sx={{
                width: settingData.isMobile ? 1 : '1000rem',
                minHeight: settingData.innerHeight,
                alignItems: 'center',
                backgroundSize: settingData.isMobile ? 'cover' : '100% 100%',
                backgroundImage: `url(${getImgPath('cr/post_bg_m.png')})`,
            }}>

                {/* LOGO */}
                <Stack sx={{
                    width: 1,
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    mb: 1,
                }}>
                    <Typography sx={{
                        fontSize: '25rem',
                        textAlign: 'center',
                        fontFamily: 'Lato, sans-serif',
                    }}>
                        Your #1 stop for the
                    </Typography>

                    <Box component={'img'}
                        src={JSTool.getImgPath('cr/logo.png')}
                        sx={{
                            width: '220rem',
                            my: 0.5,
                            mx: 2,
                        }} />

                    <Typography sx={{
                        fontSize: '25rem',
                        textAlign: 'center',
                        fontFamily: 'Lato, sans-serif',
                    }}>
                        anime!
                    </Typography>
                </Stack>

                {/* Title */}
                <Stack
                    sx={{
                        width: settingData.isMobile ? 0.8 : '700rem',
                        height: '90rem',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }} >
                    <Box
                        component={'div'}
                        sx={{
                            mx: 1.5,
                            mb: 1.3,
                            textAlign: 'center',
                            color: '#FF5E00',
                            fontSize: settingData.isMobile ? '12px' : '24rem',
                            fontFamily: 'Lato, sans-serif',
                        }}
                        dangerouslySetInnerHTML={{
                            __html:
                                `
                           ${hintTxt}
                            `
                        }} />

                </Stack>

                {/* 第一个 */}
                <Stack
                    sx={{
                        flexDirection: 'row',
                        mt: '10rem',
                        alignItems: 'center',
                    }} >

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('cr/post_022.png')}
                        sx={{
                            height: '300rem',
                            borderLeft: '5px solid #FF5E00',
                            borderBottom: '5px solid #FF5E00',
                            mr: settingData.isMobile ? 2 : 3,
                        }} />

                    <Stack sx={{
                        height: '1',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}>

                        <Typography sx={{
                            alignSelf: 'flex-start',
                            color: 'white',
                            fontSize: '25rem',
                            fontWeight: 'bold',
                            fontFamily: 'Lato, sans-serif',
                        }}>
                            Play
                        </Typography>

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '14rem',
                                py: 1,
                                lineHeight: '25rem',
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_0}
                            `
                            }} />

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '14rem',
                                lineHeight: '25rem',
                                mt: settingData.isMobile ? 0.5 : 1.5,
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_0_2}
                            `
                            }} />

                        <Typography
                            onClick={() => {
                                window.open('https://cr-sdk-production.aplusjapan-game.com/api/sonyuser/v1/lon_link/Dv4dv/mfkz/gefo');
                            }}
                            sx={{
                                px: 2,
                                borderRadius: '20rem',
                                height: '36rem',
                                lineHeight: '36rem',
                                color: 'black',
                                cursor: 'pointer',
                                bgcolor: '#FF5E00',
                                fontSize: '16rem',
                                fontWeight: 'bold',
                                mt: 0.5,
                            }}>
                            PLAY NOW
                        </Typography>
                    </Stack>
                </Stack>

                {/* 第二个 */}
                <Stack
                    sx={{
                        flexDirection: 'row',
                        mt: '50rem',
                        alignItems: 'center',
                    }} >

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('cr/post_1.png')}
                        sx={{
                            height: '300rem',
                            borderLeft: '5px solid #FF5E00',
                            borderBottom: '5px solid #FF5E00',
                            mr: settingData.isMobile ? 2 : 3,
                        }} />

                    <Stack sx={{
                        height: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}>

                        <Typography sx={{
                            alignSelf: 'flex-start',
                            color: 'white',
                            fontSize: '25rem',
                            fontWeight: 'bold',
                            fontFamily: 'Lato, sans-serif',
                        }}>
                            Watch
                        </Typography>

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '14rem',
                                py: 1,
                                lineHeight: '25rem',
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_1}
                            `
                            }} />
                        <Typography
                            onClick={() => {
                                window.open('https://lon.sng.link/Dv4dv/jv0w/ck14');
                            }}
                            sx={{
                                px: 2,
                                borderRadius: '20rem',
                                height: '36rem',
                                lineHeight: '36rem',
                                color: 'black',
                                cursor: 'pointer',
                                bgcolor: '#FF5E00',
                                fontSize: '16rem',
                                fontWeight: 'bold',
                            }}>
                            BUY TICKETS
                        </Typography>
                    </Stack>
                </Stack>

                {/* 第三个 */}
                <Stack
                    sx={{
                        flexDirection: 'row',
                        mt: '50rem',
                        alignItems: 'center',
                        borderBottom: '5px solid #FF5E00',
                    }} >

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('cr/post_44_3.png')}
                        sx={{
                            height: '300rem',
                            borderLeft: '5px solid #FF5E00',
                            mr: settingData.isMobile ? 2 : 3,
                        }} />

                    <Stack sx={{
                        height: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'center',

                    }}>

                        <Box component={'img'} 
                        src={JSTool.getImgPath('cr/cr_logo2.png')} 
                        sx={{
                            width: '40rem',
                            height: '40rem',
                            alignSelf: 'flex-end',
                        }}/>

                        <Typography
                            sx={{
                                alignSelf: 'flex-start',
                                color: 'white',
                                fontSize: '25rem',
                                fontWeight: 'bold',
                                fontFamily: 'Lato, sans-serif',
                            }}>
                            Build Your Guild
                        </Typography>

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '14rem',
                                py: 1,
                                lineHeight: '25rem',
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_2}
                            `
                            }} />

                        <Typography
                            onClick={() => {
                                window.open("https://lon.sng.link/Dv4dv/xs35/k7ab");
                            }}
                            sx={{
                                px: 2,
                                borderRadius: '20rem',
                                height: '36rem',
                                lineHeight: '36rem',
                                color: 'black',
                                cursor: 'pointer',
                                bgcolor: '#FF5E00',
                                fontSize: '16rem',
                                fontWeight: 'bold',
                                my: 1,
                            }}>
                            SHOP NOW
                        </Typography>


                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '11rem',
                                pt: 1,
                                lineHeight: '25rem',
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_2_2}
                            `
                            }} />

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '12rem' : '7rem',
                                pb: 1,
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                          ©Kugane Maruyama,PUBLISHED BY KADOKAWA CORPORATION/OVERLORD3PARTNERS
                            `
                            }} />

                    </Stack>
                </Stack>

                {/* 第四个 */}
                <Stack
                    sx={{
                        flexDirection: 'row',
                        my: '50rem',
                        alignItems: 'center',
                    }} >

                    <Box
                        component={'img'}
                        src={JSTool.getImgPath('cr/post_33.png')}
                        sx={{
                            height: '300rem',
                            borderLeft: '5px solid #FF5E00',
                            borderBottom: '5px solid #FF5E00',
                            mr: settingData.isMobile ? 2 : 3,
                        }} />

                    <Stack sx={{
                        height: '1',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                    }}>

                        <Typography sx={{
                            alignSelf: 'flex-start',
                            color: 'white',
                            fontSize: '25rem',
                            fontWeight: 'bold',
                            fontFamily: 'Lato, sans-serif',
                        }}>
                            Stream
                        </Typography>

                        <Box
                            component={'div'}
                            sx={{
                                color: 'white',
                                fontSize: settingData.isMobile ? '17rem' : '14rem',
                                py: 1,
                                lineHeight: '25rem',
                                width: '300rem',
                                fontFamily: 'Lato, sans-serif',
                            }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    `
                           ${txt_3}
                            `
                            }} />

                        <Typography
                            onClick={() => {
                                window.open("https://crunchyroll.sng.link/Ak3aj/mqix/hf93");
                            }}
                            sx={{
                                px: 2,
                                borderRadius: '20rem',
                                height: '36rem',
                                lineHeight: '36rem',
                                color: 'black',
                                cursor: 'pointer',
                                bgcolor: '#FF5E00',
                                fontSize: '16rem',
                                fontWeight: 'bold',
                                mt: 2.5,
                            }}>
                            WATCH NOW
                        </Typography>
                    </Stack>
                </Stack>

                <Footer isCR={true} />
                <SubmitEmailAlert_CR_Poster />
            </Stack>
        </Stack>
    );
}
import $ from 'jquery';
import jqueryColor from 'jquery-color';
import { settingObj } from './SettingSlice';

let FlagDic = {
    navFlag: 0,
};

export function setViewHidden(isBlack) {
    if (isBlack && FlagDic.navFlag == 0) {
        $("#home_page_left_view").fadeIn('fast', () => { });
        // $("#home_page_right_view").fadeIn('fast', () => { });
        $("#subscript_view").fadeIn('fast', () => { });
        FlagDic.navFlag = 1;
    } else if (!isBlack && FlagDic.navFlag == 1) {
        $("#home_page_left_view").fadeOut('fast', () => { });
        // $("#home_page_right_view").fadeOut('fast', () => { });
        $("#subscript_view").fadeOut('fast', () => { });
        FlagDic.navFlag = 0;
    }
}

export function scrollToTop(pageId) {
    $("#" + pageId).animate({
        scrollTop: 0,
    }, "slow");
}

export function scrollToView(viewId) {
    $("#home_page_view").animate({
        scrollTop: $(`#${viewId}`).offset().top + $(`#home_page_view`).scrollTop() - $("#nav_view").height()
    }, "slow");
}

export function isShowing(elId) {
    try {
        if (elId == null || elId == undefined || elId == '') {
            return false;
        }

        let el = $('#' + elId)[0];
        if (el) {
            let h = $(window).height();
            let top = el.getBoundingClientRect().top;
            return top < (h - 50) && top > -el.offsetHeight;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}

export function isFirstShowing(elId) {
    if (elId == null || elId == undefined || elId == '') {
        return false;
    }
    if ($('#' + elId).hasClass('first_showing_class')) {
        return false;
    }
    if (isShowing(elId)) {
        $('#' + elId).addClass('first_showing_class');
        return true;
    };
    return false;
}


export function isEmail(str) {
    if (isEmpty(str) || str.length < 1 || str.length > 255) {
        return false;
    }
    return str.match('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}');
}

export function isEmpty(str) {
    if (str === null || str === undefined || !str || str == "" || 'undefined' == str || 'null' == str) {
        return true;
    }
    return false;
}

let os = function () {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();

export let isMobile = function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    }
    return false;
}();

export let isAndroid = function () {
    if (os.isAndroid || (navigator.userAgent.toLowerCase().indexOf("android") !== -1)) {
        return true;
    }
    return false;
}();

export let isIOS = function () {
    let str = navigator.userAgent.toLowerCase();
    if (os.isPhone || (str.indexOf("iphone") !== -1) || str.indexOf("ipad") !== -1) {
        return true;
    }
    return false;
}();

export function getImgPath(name) {
    if (isEmpty(name)) {
        return '';
    }
    if (name.indexOf('game-cdn.aplusjapan-game.com') != -1) {
        return name;
    }
    return 'https://game-cdn.aplusjapan-game.com/overlord/img/' + name;
}

export function saveLocalString(key, value) {
    if (typeof window !== 'undefined') {
        if (value == null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }
}

export function getLocalString(key) {
    if (typeof window !== 'undefined') {
        const str = localStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function saveSessionString(key, value) {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
}

export function getSessionString(key) {
    if (typeof window !== 'undefined') {
        const str = sessionStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function removeLocalString(key) {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
    }
}

export function clearLocalString() {
    if (typeof window !== 'undefined') {
        localStorage.clear();
    }
}


export function showMenu(idStr) {
    settingObj.menuId = idStr;
    $("#_mask_view").fadeIn();
    $(`#${settingObj.menuId}`).slideDown(250);
    settingObj.showMenu = true;

    //菜单动画打开
    if ('menu_view' == idStr) {
        var nav = $('.btn-nav');
        var navbox = $('.m-nav-box');

        nav.addClass('z-close');
        navbox.addClass('z-open');
    }
}

export function hideMenu() {
    $("#_mask_view").fadeOut();
    $(`#${settingObj.menuId}`).slideUp(250);
    settingObj.showMenu = false;

    //菜单动画关闭
    if ('menu_view' == settingObj.menuId) {
        var nav = $('.btn-nav');
        var navbox = $('.m-nav-box');

        nav.removeClass('z-close');
        if (navbox.hasClass('z-open')) {
            navbox.removeClass('z-open');
        }
    }
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function appleTap(){
    const ad = settingObj.platform;
    if(isCR()){
        let url = 'https://apps.apple.com/us/app/id6511215276';
        if('fbpreg' == ad){
            // window.fbq('track','lon_cr_ios_' + ad); //FB埋点
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6511215276?ppid=92de1223-03b5-40e1-84d6-53b148d394c0'

        }else if('ttpreg' == ad){
            // window.ttEvent('ClickButton', 'cr_' + ad); //TT埋点
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6511215276?ppid=68d1149f-0557-41c1-a87c-714b14d21d6e'

        }else if('ggpreg' == ad){
            window.gtag('event', 'lon_cr_ios_' + ad); //GG埋点
            if(!isEmpty(ad)){
                window.gg_ad_conversion('qQqICObbks8ZEMaakr8B'); //GG广告
            }    
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6511215276?ppid=ff127f6a-e42b-408d-88c1-3c08e6e856fd';

        }
        window.open(url);

       
    }else{
        let url = 'https://apps.apple.com/fr/app/id6499023982';
        if('fbpreg' == ad){
            // window.fbq('track','lon_apj_ios_' + ad); //FB埋点
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6499023982?ppid=17865e0a-5869-41b0-be0d-e60f93507959';

        }else if('ttpreg' == ad){
            // window.ttEvent('ClickButton', 'apj_' + ad); //TT埋点
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6499023982?ppid=2d9b8b06-50c9-415a-b0a4-b67f802978d4';

        }else if('ggpreg' == ad){
            window.gtag('event', 'lon_apj_ios_' + ad); //GG埋点
            if(!isEmpty(ad)){
                window.gg_ad_conversion('qQqICObbks8ZEMaakr8B'); //GG广告
            }
            url = 'https://apps.apple.com/app/lord-of-nazarick/id6499023982?ppid=405e9191-cc11-42a0-9cc1-a9db372e14e7';
        }
        window.open(url);
    }
}

export function googleTap(){
    const ad = settingObj.platform;

    if(isCR()){
        if('fbpreg' == ad){
            // window.fbq('track','lon_cr_android_' + ad); //FB埋点
          
        }else if('ttpreg' == ad){
            // window.ttEvent('ClickButton', 'cr_' + ad); //TT埋点
          
        }else if('ggpreg' == ad){
            window.gtag('event', 'lon_cr_android_' + ad); //GG埋点
            if(!isEmpty(ad)){
                window.gg_ad_conversion('qQqICObbks8ZEMaakr8B'); //GG广告
            }    
        }

        let url = 'https://play.google.com/store/apps/details?id=com.crunchyroll.crg.overlord';
        window.open(url);
       
    }else{
        if('fbpreg' == ad){
            // window.fbq('track','lon_apj_android_' + ad); //FB埋点
          
        }else if('ttpreg' == ad){
            // window.ttEvent('ClickButton', 'apj_' + ad); //TT埋点
          
        }else if('ggpreg' == ad){
            window.gtag('event', 'lon_apj_android_' + ad); //GG埋点
            if(!isEmpty(ad)){
                window.gg_ad_conversion('qQqICObbks8ZEMaakr8B'); //GG广告
            }
        }

        let url = 'https://play.google.com/store/apps/details?id=com.olapj.eu';
        window.open(url);
    }
}

export function twitterTap(){
    trackEvent('twitter_taped')
    window.open('https://x.com/LoNGameOfficial');
}

export function discordTap(){
    trackEvent('discord_taped');
    window.open('https://got.cr/LoNDiscord');
}

export function trackEvent(name){
    // window.fbq('track',name); //FB埋点
    window.gtag('event', name); //GG埋点
}

export function isCR(){
    const x = window.location.origin;
    if (x.indexOf('lordofnazarickgame.com') != -1) {
        return true;
    }
    return false;
}

export function isAPJ(){
    const x = window.location.origin;
    if (x.indexOf('aplus-games.com') != -1) {
        return true;
    }
    return false;
}

import { createSlice } from '@reduxjs/toolkit';
import LangJson from './lang.json';
import * as JSTool from './JSTool';

const LANG = 'en';
export const settingObj = {
    navIndex: 0,
    lang: LANG,
    showMenu: false,
    showNewsDetailIndex: -1, //new_id, 小于0隐藏
    platform: '',
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        navIndex: 0,
        lang: LANG,
        langDic: getLangDic(LANG),
        isMobile: false,
        showToast: null,
        showLoading: false,
        showRegisterAlert: false,
        showCREmailAlert: false,
        isPad: false,
        showNewsDetailIndex: -1, //new_id, 小于0隐藏
        innerHeight: window.innerHeight,
        submitedEmail: null,
        submitedEmailCR: null,
        newsList: null,
        platform: '',
    },
    reducers: {
        setPlatform: (state, action) => {
            state.platform = action.payload;
            settingObj.platform = action.payload;
        },

        setNewsList: (state, action) => {
            state.newsList = action.payload;
        },
        setSubmitedEmail: (state, action) => {
            state.submitedEmail = action.payload;
        },

        setSubmitedEmailCR: (state, action) => {
            state.submitedEmailCR = action.payload;
        },

        refreshInnerHeight: (state, action) => {
            state.innerHeight = window.innerHeight;
        },

        showNewsDetailIndex: (state, action) => {
            state.showNewsDetailIndex = action.payload;
            settingObj.showNewsDetailIndex = action.payload;
        },

        showRegisterAlert: (state, action) => {
            state.showRegisterAlert = action.payload;
        },

        showCREmailAlert: (state, action) => {
            state.showCREmailAlert = action.payload;
        },

        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        
        setNavIndex: (state, action) => {
            state.navIndex = action.payload;
            settingObj.navIndex = action.payload;
        },

        showLoading: (state, action) => {
            state.showLoading = action.payload;
        },

        showToast: (state, action) => {
            state.showToast = action.payload;
        },

        setLanguage: (state, action) => {
            state.langDic = getLangDic(action.payload);
            state.lang = action.payload;
            settingObj.lang = action.payload;
        },
    }
});

function getLangDic(l) {
    for (const key in LangJson) {
        if (key == l) {
            return LangJson[l];
        }
    }
    return null;
}

export const settingActions = settingSlice.actions
export default settingSlice.reducer

import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import * as JSTool from '../store/JSTool';
import { settingActions, settingObj } from '../store/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

export default function NavView(props) {

    const settingData = useSelector(state => state.settingData);

    React.useEffect(() => {


    }, []);

    function logoTap() {
        window.location.href = window.location.origin;
    }

    return (
        <Stack
            id={'nav_view'}
            sx={{
                position: 'fixed',
                bgcolor: '#000000dd',
                height: '72rem',
                zIndex: 100,
                width: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: '1155px',
            }}>

            <Box
                onClick={logoTap}
                component={'img'}
                src={JSTool.getImgPath('logo4.png')}
                sx={{
                    height: 0.8,
                    mr: 4,
                    cursor: 'pointer',
                }} />

            <Box
                component={'img'}
                src={JSTool.getImgPath('nav_icon_left.png')}
                sx={{
                    height: '25rem',
                }} />

            {props.isDownloadPage &&
                <Stack sx={{
                    flexDirection: 'row',
                }}>
                    < NavItem index={0} />
                    <NavItem index={1} />
                    <NavItem index={3} />
                </Stack>
            }

            {!props.isDownloadPage &&
                <Stack sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    < NavItem index={0} />
                    {/* <NavItem index={1} /> */}
                    <NavItem index={2} />
                    <NavItem index={3} />
                    <NavItem index={4} />
                    <TopUp/>
                </Stack>
            }



            <Box
                component={'img'}
                src={JSTool.getImgPath('nav_icon_right.png')}
                sx={{
                    height: '25rem',
                }} />

        </Stack>
    );
}

function NavItem(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector(state => state.settingData);

    function itemClick() {
        const index = props.index;
        dispatch(settingActions.setNavIndex(index));
        JSTool.scrollToView(`scroll_to${index}`);
    }

    return (
        <Stack
            onClick={itemClick}
            sx={{
                mx: 2,
            }}>

            <Typography sx={{
                cursor: 'pointer',
                lineHeight: '80px',
                textAlign: 'center',
                color: settingData.navIndex == props.index ? '#FFA129' : 'white',
                fontSize: '14rem',
                transitionDuration: '0.3s',
                fontFamily: window.MyFont,
                '&:hover': {
                    transform: 'scale(1.1)',
                }
            }}>
                {settingData.langDic[`lang${props.index}`]}
            </Typography>

        </Stack>
    );
}

function TopUp(props) {

    function shopTap(){
        if(JSTool.isCR()){
            JSTool.trackEvent('ol_cr_webshop_taped')
            window.open("https://shop.lordofnazarickgame.com/")
        }else{
            JSTool.trackEvent('ol_apj_webshop_taped')
            window.open("https://payment.aplus-games.com/paymentList?gameId=overlord")
        }
    }

    return (
        <Stack 
        onClick={shopTap}
        sx={{
            mx: 2,
            cursor: 'pointer',
            transitionDuration: '0.3s',
            fontFamily: window.MyFont,
            '&:hover': {
                transform: 'scale(1.07)',
            }
        }}>
            <Typography sx={{
                textAlign: 'center',
                color: 'white',
                fontSize: '18rem',
                fontFamily: window.MyFont,
            }}>
                TOP-UP
            </Typography>

            <Typography sx={{
                textAlign: 'center',
                color: 'white',
                fontSize: '13rem',
                fontFamily: window.MyFont1,
                backgroundSize: '100% 100%',
                backgroundImage: `url(${JSTool.getImgPath('nav_discount.png')})`
            }}>
                DISCOUNT
            </Typography>
        </Stack>
    );
}
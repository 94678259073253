import { Stack, Box, } from '@mui/material';
import * as React from 'react';
import { getImgPath } from '../store/JSTool';
import * as JSTool from '../store/JSTool';
import { useSelector, useDispatch } from 'react-redux';
import { settingActions, settingObj } from '../store/SettingSlice';

export default function FishGirlView(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);


    React.useEffect(() => {

    });

    function subscribeTap() {
        if (!settingData.isMobile) {
            dispatch(settingActions.showRegisterAlert(true));
            JSTool.trackEvent('show_email_pop');
        } else {
            if (JSTool.isIOS) {
                JSTool.appleTap();
            } else {
                JSTool.googleTap();
            }
        }
    }

    return (
        <Stack sx={{
            width: 1,
            pt: 2,
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            backgroundSize: 'cover',
            backgroundImage: `url(${getImgPath('bg1_9.png')})`,
            position: 'relative',
        }}>

            {/* 底部顶部渐变色 */}
            <Box sx={{
                width: 1,
                height: '180px',
                background: 'linear-gradient(#00000000 , #000000cc)',
                position: 'absolute',
                bottom: 0,
            }} />

            <Box sx={{
                width: 1,
                height: '180px',
                background: 'linear-gradient(#000000cc , #00000000)',
                position: 'absolute',
                top: 0,
            }} />


            <Box
                onClick={subscribeTap}
                component={'img'}
                src={getImgPath('swim5.png')}
                sx={{
                    display: props.isDownload ? 'none' : 'block',
                    width: settingData.isMobile ? '100%' : '1000rem',
                    zIndex: 2,
                    ml: settingData.isMobile ? 1 : 0,
                    cursor: 'pointer',
                    transitionDuration: '0.3s',
                    '&:hover': {
                        transform: 'scale(1.03)',
                    }
                }} />

        </Stack>
    );
}
import * as React from 'react';
import { Box, Stack, Typography, Dialog } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import * as JSTool from '../store/JSTool';
import { settingActions } from '../store/SettingSlice';
import $ from 'jquery';
import * as APJHttp from '../store/APJHttp';


export default function SubmitEmailAlert(props) {

    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);

    function closeTap() {
        dispatch(settingActions.showRegisterAlert(false));
    }

    return (
        <Dialog
            fullScreen={true}
            PaperProps={{
                style: {
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#00000099',
                    boxShadow: 'none',
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backdropFilter: 'blur(5px)',
                    WebkitBackdropFilter: 'blur(5px)',
                },
            }}
            open={settingData.showRegisterAlert}>

            <Stack sx={{
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}>
                {!settingData.submitedEmail && <EmailContainer />}
                {settingData.submitedEmail && <EmailSuccess />}

                <Box component={'img'}
                    src={JSTool.getImgPath('alert_bg.png')}
                    sx={{
                        height: '700rem'
                    }}
                />

                <Box
                    component={'img'}
                    onClick={closeTap}
                    src={JSTool.getImgPath('close1.png')}
                    sx={{
                        alignSelf: 'flex-end',
                        cursor: 'pointer',
                        width: '20rem',
                        position: 'absolute',
                        zIndex: 10,
                        top: '30rem',
                        right: '80rem',
                        transitionDuration: '0.3s',
                        '&:hover': {
                            transform: 'rotate(180deg)',
                        }
                    }} />
            </Stack>

        </Dialog>
    );
}

function EmailContainer() {

    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);
    const settingData = useSelector(state => state.settingData);


    function submitTap() {
        const e0 = $('#input_one')[0].value;
        const e1 = $('#input_two')[0].value;

        if (!JSTool.isEmail(e0) || !JSTool.isEmail(e1)) {
            dispatch(settingActions.showToast('Please enter a valid email address'));
            return
        }

        if (e0 != e1) {
            dispatch(settingActions.showToast('The two Emails are different'));
            return;
        }

        if (!checked) {
            dispatch(settingActions.showToast('Please agree the protocol'));
            return;
        }

        //提交网络请求
        JSTool.trackEvent('email_submit');
        dispatch(settingActions.showLoading(true));
        APJHttp.addEmail(e0, (data) => {
            dispatch(settingActions.showLoading(false));
            dispatch(settingActions.setSubmitedEmail(e0));
        }, (code, msg) => {
            dispatch(settingActions.showLoading(false));
            dispatch(settingActions.showToast(`code ${code}: ${msg}`));
        })


    }

    function checkTap() {
        setChecked((oldState) => {
            return !oldState;
        });
    }

    return (
        <Stack sx={{
            width: 0.61,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: settingData.isMobile ? '80rem' : '90rem',
        }}>
            <Stack sx={{
                bgcolor: 'white',
                height: '40rem',
                width: '1',
                flexDirection: 'row',
            }}>
                <Typography sx={{
                    width: '230rem',
                    height: 1,
                    bgcolor: '#BD9458',
                    fontSize: '16rem',
                    color: 'white',
                    textAlign: 'left',
                    lineHeight: '40rem',
                    pl: 1.5,
                }}>
                    Please enter your email:
                </Typography>

                <Stack
                    id='input_one'
                    component={'input'}
                    type={'email'}
                    placeholder={''}
                    sx={{
                        px: 2,
                        fontSize: '16rem',
                        border: 0,
                        outline: 'none',
                        color: 'black',
                        flex: 1,
                        '&::placeholder': {
                            color: "gray",
                            fontFamily: '-moz-initial',
                        },
                    }} />

            </Stack>

            <Stack sx={{
                mt: 1,
                bgcolor: 'white',
                height: '40rem',
                width: '1',
                flexDirection: 'row',
            }}>

                <Typography sx={{
                    width: '230rem',
                    height: 1,
                    bgcolor: '#BD9458',
                    fontSize: '16rem',
                    color: 'white',
                    textAlign: 'left',
                    lineHeight: '40rem',
                    pl: 1.5,
                }}>
                    Please re-enter your email:
                </Typography>

                <Stack
                    id='input_two'
                    component={'input'}
                    type={'email'}
                    placeholder={''}
                    sx={{
                        px: 2,
                        fontSize: '16rem',
                        border: 0,
                        outline: 'none',
                        color: 'black',
                        flex: 1,
                        '&::placeholder': {
                            color: "gray",
                            fontFamily: '-moz-initial',
                        },
                    }} />

            </Stack>

            <Stack sx={{
                mt: 1,
                bgcolor: 'white',
                width: '1',
                position: 'relative',
            }}>
                <Box
                    component={'div'}
                    sx={{
                        bgcolor: 'white',
                        fontSize: '15rem',
                        color: 'black',
                        fontWeight: 'bold',
                        textAlign: 'left',
                        lineHeight: '20rem',
                        pl: '45rem',
                        py: 1,
                    }}
                    dangerouslySetInnerHTML={{
                        __html:
                            `
                        ${JSTool.isCR() ? 'By submitting your email address, you acknowledge that you have read Crunchyroll\'s <a target="_blank" href="https://www.sonypictures.com/corp/privacy.html">Privacy Policy</a>. You can opt out at any time.'
                                : 'Stay informed about all the latest updates on Lord of Nazarick! Subscribe and receive news from email.aplus-games.com'}
                         `
                    }} />

                <Box component={'img'}
                    onClick={checkTap}
                    src={checked ? JSTool.getImgPath('checked.png') : JSTool.getImgPath('uncheck.png')}
                    sx={{
                        width: '19rem',
                        position: 'absolute',
                        left: '10rem',
                        top: '10rem',
                        cursor: 'pointer'
                    }} />

            </Stack>

            <Typography
                onClick={submitTap}
                sx={{
                    mt: 2,
                    width: '130rem',
                    height: '40rem',
                    bgcolor: '#BD9458',
                    fontSize: '16rem',
                    color: 'white',
                    textAlign: 'center',
                    lineHeight: '40rem',
                    pl: 1.5,
                    borderRadius: '5rem',
                    cursor: 'pointer',
                }}>
                Submit
            </Typography>

        </Stack>
    );
}


function EmailSuccess(props) {

    return (
        <Typography sx={{
            fontSize: '22rem',
            fontWeight: 'bold',
            color: 'white',
            position: 'absolute',
            bottom: '190rem',
            bgcolor: '#C29A62',
            px: 2,
            py: 2,
        }}>
            You have successfully submitted the Email !
        </Typography>
    );


}

import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import * as APJTool from '../store/JSTool';
import country from '../store/country.json';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';

export default function LoadingPage(props) {

    let countTmp = 0;
    let isFinish = false;
    const dispatch = useDispatch();
    const settingData = useSelector(state => state.settingData);
    const [percent, setPercent] = React.useState(0);

    React.useEffect(() => {
        preloadImages();
        startAni();
    }, []);

    function finishLoading() {
        setTimeout(() => {
            $("#loading_page").fadeOut("slow");
        }, 500);
    }

    function preloadImages() {
        let mainfest = [
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_1_2.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_2_2.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_3_2.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_4_2.jpg",
            "https://game-cdn.aplusjapan-game.com/overlord/img/poster/p_5_2.jpg",

            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role5_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role1_2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role7_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role0_2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role2_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role4_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role0_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role6_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role3_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/b_role1_blue.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Ainz_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Ainz_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_left_1.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_right_1.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Albedo_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Aura_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Aura_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Cocytus_right2.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Demiurge_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Demiurge_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Mare_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Mare_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Momon_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Momon_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Sebas_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Sebas_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Shalltear_left.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/role/Shalltear_right.png",
            "https://game-cdn.aplusjapan-game.com/overlord/img/alert_bg.png"
        ];

        var preload = {
            startPreload: function () {
                var preload = new window.createjs.LoadQueue(true);
                // preload.installPlugin(window.createjs.SOUND);
                // preload.installPlugin(window.createjs.VIDEO);

                preload.addEventListener("progress", this.handleFileProgress);
                preload.addEventListener("complete", this.loadComplete);

                preload.setMaxConnections(3);
                preload.loadManifest(mainfest);
            },

            handleFileProgress: function (event) {
                // const num = Math.ceil(event.loaded * 100);
                // if (num > countTmp) {
                //     countTmp = num;
                //     console.log("handleFileProgress: " + num);
                //     setPercent(num);
                // }
            },

            loadComplete: function () {
                // if (!isFinish) {
                //     countTmp = 100;
                //     isFinish = true;
                //     console.log("loadComplete");
                //     finishLoading();
                // }
            }
        }
        preload.startPreload();
    }

    function startAni() {
        let timer = setInterval(function () {
            if (countTmp >= 100) {
                clearInterval(timer);
                if (!isFinish) {
                    isFinish = true;
                    finishLoading();
                }
            } else {
                let randNum = Math.floor(Math.random() * 5);
                countTmp += randNum;
                setPercent(prevCount => {
                    let x = prevCount + randNum;
                    if (x > 100) {
                        x = 100;
                    }
                    return x;
                });
            }
        }, 50);
    }

    return (
        <Stack
            id='loading_page'
            sx={{
                width: 1,
                minWidth: settingData.isMobile ? 1 : '1000px',
                height: window.innerHeight,
                minHeight: '600px',
                bgcolor: 'black',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 300
            }}>
            <Box
                src={APJTool.getImgPath('big_logo.png')}
                component={'img'}
                sx={{
                    height: settingData.isMobile ? 'auto' : '500rem',
                    width: settingData.isMobile ? '90%' : 'auto',
                    mb: '100rem',
                }} />

            <Stack sx={{
                width: '700rem',
                height: '260rem',
                position: 'absolute',
                bottom: 0,
                flexDirection: 'column',
            }}>

                <Box
                    src={APJTool.getImgPath('progress_black.png')}
                    component={'img'}
                    sx={{
                        height: '20rem',
                        mt: '110rem',
                    }} />

                <Box
                    src={APJTool.getImgPath('progress_blue.png')}
                    component={'img'}
                    sx={{
                        height: '4rem',
                        width: `${percent * 0.94}%`,
                        ml: '21rem',
                        mt: '114rem',
                        position: 'absolute',
                    }} />

                <Box
                    src={APJTool.getImgPath('progress_rat.gif')}
                    component={'img'}
                    sx={{
                        height: '150rem',
                        position: 'absolute',
                        left: `${-200 + percent * 6.5}rem`,
                    }} />

                <Typography sx={{
                    color: 'white',
                    fontSize: '25rem',
                    width: 1,
                    mt: 1,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontFamily: window.MyFont1,
                }}>
                    {percent} %
                </Typography>
            </Stack>
        </Stack>
    );
}

